/***************************************************
  Imports
***************************************************/

import React from "react"

import { useRecoilValue } from "recoil"
import { linkedSubPagesState } from "../state"

import Layout from "../components/Layout"
import List from "../components/List"
import PageNumber from "../components/PageNumber"
import DropDowmMenu from "../components/DropDownMenu"

/***************************************************
  Component
***************************************************/

export default ({
  linkSubPageGroupID,
  title,
  description,
  subTitle,
  summaryInfo,
  titleImg,
  infoList,
  features,
  footerImgs,
}) => {
  const subPageGroup = useRecoilValue(linkedSubPagesState(linkSubPageGroupID))

  const infoListUI = () => {
    return (
      <section className="section-info-list">
        {infoList.map((info, index) => {
          return (
            <div
              className={`${
                index % 2 == 0 ? "col-4" : info.spread ? "col-4" : "col-9"
              } ${info.spread ? "span-9" : "span-4"}`}
              key={info.title}
            >
              <h2>{info.title}</h2>
              {typeof info.text === "string" ? (
                <p dangerouslySetInnerHTML={{ __html: info.text }}></p>
              ) : (
                info.text
              )}
            </div>
          )
        })}
      </section>
    )
  }

  const featuresUI = () => {
    return (
      <section className="section-features">
        <PageNumber className="col-3 span-11" number="01" text="FEATURES" />
        <div className="col-4 span-11">
          <h2 className="color-white">{features.title}</h2>
          <List className="no-style" items={features.items} flex />
        </div>
      </section>
    )
  }

  const footerImgUI = () => {
    return (
      <section className="section-footer-images">
        {footerImgs.map((image, index) => (
          <img
            className={`${index % 2 == 0 ? "col-4" : "col-9"} span-4`}
            src={image}
          />
        ))}
      </section>
    )
  }

  return (
    <Layout
      headerTheme="dark"
      className="page-type-e"
      title={title}
      description={description}
    >
      <section className="section-hero">
        <div className="col-4 span-9">
          {!subPageGroup ? (
            <h1>{title}</h1>
          ) : (
            <DropDowmMenu
              title={subPageGroup.title}
              options={subPageGroup.options}
              active={title}
            />
          )}

          {subTitle && <h2>{subTitle}</h2>}
        </div>
      </section>

      {summaryInfo && (
        <section className="section-hero-summary">
          <div className="col-4 span-9">{summaryInfo()}</div>
        </section>
      )}

      {titleImg && (
        <section className="section-hero-image">
          <img className="col-4 span-8" src={titleImg} alt={title} />
        </section>
      )}

      {features && featuresUI()}

      {infoList && infoListUI()}

      {footerImgs && footerImgUI()}
    </Layout>
  )
}
