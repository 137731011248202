/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"
import List from "../../components/List"

import TitleImg from "../../images/industries-distribution.png"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="industries-and-finance">
      <p>
        Wholesale distributors occupy a middle position in the supply chain
        between manufacturers and secondary distributors or retailers for a wide
        range of durable and non-durable goods, industrial goods, and consumer
        products. This positioning presents unique challenges to profitability.
        In today's competitive global marketplace- where supply networks are
        becoming increasingly complex and customers demand more choices, faster
        fulfillment, and lower prices- wholesale distributors often find
        themselves squeezed from both sides.
        <br />
        <br />
        Increasingly, satisfying diverse customers means providing custom
        products and packaging. A growing number of items and SKUs (stock
        keeping units) can add complexity and cost to inventory management. At
        the same time, global competition means that retailers can, and do,
        demand faster service, lower prices, and compliance with requirements
        such as radio frequency identification (RFID). Finally, companies of all
        sizes conduct business across international borders, which adds the
        complexity of dealing with multiple currencies, languages, market
        expectations, and regulatory requirements. As a result, many wholesale
        distributors struggle to meet customer needs while still turning a
        profit.
        <br />
        <br />
        To survive and thrive in this pressured supply chain position, a
        wholesale distribution company needs to control already tight margins by
        increasing efficiency and eliminating waste. Yet many distributors find
        themselves hindered by manual or disconnected processes, information
        delays, excess inventory, and purchasing information that's locked in
        the minds of individual employees. The good news is that technology can
        help by integrating processes, streamlining information flows, and
        providing easy access to the information required for confident
        decision-making and operational improvement.
      </p>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Distribution"
      description="Deliver integrated functionality to support all your order entry, inventory, warehouse operation, fulfillment, shipping, and financial management needs."
      linkSubPageGroupID="IndustriesGroup"
      subTitle="Creating Greatest Value in Tough Times"
      titleImg={TitleImg}
      summaryInfo={summaryInfo}
      infoList={[
        {
          title:
            "Optimize Efficiency and Profitability with Microsoft Dynamics Business Central",
          text: (
            <p>
              Microsoft Dynamics Business Central delivers integrated
              functionality to support all your order entry, inventory,
              warehouse operation, fulfillment, shipping, and financial
              management needs so you can get goods to customers quickly and
              profitably. Microsoft Dynamics Business Central offers wholesale
              distributors the ability to:
              <List
                className="concise dark"
                items={[
                  {
                    text: `automate manual procedures`,
                  },
                  {
                    text: `improve replenishment decisions`,
                  },
                  {
                    text: `process orders quickly`,
                  },
                  {
                    text: `improve fill rates and customer satisfaction`,
                  },
                  {
                    text: `access quick feedback on business performance`,
                  },
                  {
                    text: `get strong returns on inventory investment`,
                  },
                ]}
              />
            </p>
          ),
        },
      ]}
    />
  )
}
